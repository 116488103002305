import React from "react";
import styled from "styled-components";
import { useSpring, animated } from "react-spring";
import { ParallaxProvider, Parallax } from "react-scroll-parallax";
import { useInView } from "react-intersection-observer";

/* const amazonLink =
  "https://www.amazon.com/dp/B0D3LZVBTR/ref=sr_1_76?crid=2OBVLA5ZIU7V1&dib=eyJ2IjoiMSJ9.eu4TZHCjGHDOxp8YFLLg88HjAs_6UfRF2NT_p3EjJ_dvmwYdc9kmLHLkvcC32xQ3k_-4TxPDLMP2J-wBJTLOL65SKAFgnMhR_17gjMp-ft_d-Ip3cY0aR7UKqU3WHc3TAl87YWvXmuCShvkJvWgwEWjGzY8YiyjnSKrZvlsTGwuSDT4yfdXWQHtWaTZi4KT1.j_0zQUKEFUI8nZysIyu-jQboKCiwt7j5HvKZKBrPtSo&dib_tag=se&keywords=my+manifestation+journal&qid=1715181603&sprefix=mymanifestation+journal%2Caps%2C483&sr=8-76";
 */
const affiliateLinkManifestationJournal = "https://amzn.to/3V00ckj";

const etsyLink =
  "https://www.etsy.com/dk-en/listing/1724287472/my-manifestation-journal";
const Container = styled.div`
  text-align: center;
  color: #fff;
`;

const CoverSection = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
`;

const CoverImage = styled(animated.div)`
  background: url("cover.png") repeat center center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80vmin;
  background-size: cover;
`;

const PointDown = styled(animated.div)`
  font-size: 2em;
  background: rgba(0, 0, 0, 0.5);
  padding: 2px;
  border-radius: 7px;
  position: absolute;
  bottom: 60px;
  right: 20px;
`;

const Menu = styled(animated.div)`
  font-size: 0.75rem;
  padding: 6px;
  border-radius: 7px;
`;

const HeroText = styled(animated.div)`
  font-size: 2em;
  background: rgba(255, 255, 255, 0.5);
  padding: 20px;
  border-radius: 7px;
  color: black;
  font-family: "Oswald", sans-serif;
`;

const Section = styled(animated.div)<{ backgroundColor?: string }>`
  padding: 40px 20px;
  background: ${(props) => props.backgroundColor || "white"};
  border-radius: 2px;
  color: black;
`;

const SectionTitle = styled.h2`
  font-size: 2em;
  margin-bottom: 20px;
  font-family: "Oswald", sans-serif;
`;

const SectionContent = styled.p`
  font-size: 1.2em;
  max-width: 800px;
  margin: 0 auto;
  font-family: "Montserrat", sans-serif;
`;

const Button = styled.a`
  margin: 20px;
  padding: 15px 30px;
  font-size: 1.2em;
  color: #fff;
  background-color: #3498db;
  border: none;
  border-radius: 30px;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #2980b9;
  }
`;

const BookPreview = styled.img`
  width: 100%;
  max-width: 300px;
  margin: 20px 0;
  border-radius: 10px;
  border: 1px solid black;
`;

const SmallBookPreview = styled.img`
  width: 100%;
  max-width: 100px;
  border-radius: 10px;
  border: 1px solid #3498db;
  margin-bottom: -20px;
`;

const Social = styled.img`
  width: 100%;
  max-width: 50px;
  margin: 20px 0;
  border-radius: 10px;
`;

const SubHeader = styled.h3`
  font-family: "Oswald", sans-serif;
`;

const SubSection = styled.div`
  font-size: 16px;
  line-height: 1.5;
`;

const Italic = styled.i`
  font-family: "Montserrat", sans-serif;
`;

const Link = styled.a`
  color: #3498db;
  text-decoration: underline;
  line-height: 2;
  font-size: 0.75em;
`;

const Banner = styled.div`
  width: 100%;
  height: 29px;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 100;
  position: fixed;
`;

const App = () => {
  const { ref: sectionRef1, inView: inView1 } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });
  const { ref: sectionRef2 } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });
  const { ref: sectionRef3, inView: inView3 } = useInView({
    threshold: 0.1,
  });

  const { ref: coverImage, inView: coverInView } = useInView({
    threshold: 0.1,
  });

  const fadeIn = useSpring({
    transform: inView1 ? "translateY(0)" : "translateY(50px)",
  });

  const slideIn = useSpring({
    from: {
      transform: "scale(0)",
    },
    to: {
      transform: "scale(1)",
    },
    delay: 5000,
  });

  const floatUp = useSpring({
    transform: inView3 ? "translateY(0)" : "translateY(50px)",
  });

  const slide = useSpring({
    from: { backgroundPosition: "100% 100%" },
    to: [{ backgroundPosition: "0% 0%" }],
    reset: !coverInView,
    config: { duration: 10000 },
  });

  return (
    <ParallaxProvider>
      <Banner>
        <Menu>♡ SusAndew</Menu>
      </Banner>
      <Container>
        <CoverSection>
          <Parallax translateY={[-40, 40]}>
            <CoverImage ref={coverImage} style={slide}>
              <HeroText>Design your dream life</HeroText>
              <PointDown style={slideIn}>▽</PointDown>
            </CoverImage>
          </Parallax>
        </CoverSection>
        <Section ref={sectionRef1} style={fadeIn}>
          <SectionTitle>About Us</SectionTitle>
          <SectionContent>
            We are passionate about living our best lives and believe in the
            power of manifesting our dreams. Our mission is to help you
            understand that you have the power to shape your own path in life.
            Join us on this journey to unlock your potential and create the life
            you've always envisioned.
          </SectionContent>
        </Section>
        <Section
          ref={sectionRef2}
          style={floatUp}
          backgroundColor="linear-gradient(169deg, rgba(226,240,249,1) 0%, rgba(255,255,255,1) 100%)"
        >
          <SectionTitle>Our Manifestation Journal</SectionTitle>
          <SectionContent>
            <p>
              Transform your life by using our Manifestation Journal. Gain
              clarity on your goals, cultivate a positive mindset, and track
              your progress daily. Start manifesting your dreams and watch your
              life change for the better.
            </p>
            <br></br>
            <Parallax translateY={[20, -20]}>
              <Link
                href={affiliateLinkManifestationJournal}
                target="_blank"
                rel="noopener noreferrer"
              >
                <BookPreview src="book.png" alt="Book" />
              </Link>
            </Parallax>
            <Link href={etsyLink} target="_blank" rel="noopener noreferrer">
              Preview
            </Link>
            <br></br>
            <br></br>
            <SubHeader>What you will get out of this journal:</SubHeader>
            <SubSection>
              <p>
                <b>Guided Prompts:</b> Thoughtfully designed prompts to help you
                clarify your goals and intentions.
                <p>
                  <b>Inspirational Quotes:</b> Daily doses of motivation to keep
                  you inspired and focused.
                </p>
                <p>
                  <b>Goal Tracking:</b> Dedicated sections to track your
                  progress and celebrate your achievements.
                </p>
                <p>
                  <b>Gratitude Practice:</b> Space to record daily gratitude,
                  fostering a positive mindset.
                </p>
                <p>
                  <b>High-Quality Design: </b> Beautifully crafted with premium
                  materials for a luxurious feel.
                </p>
                <p>
                  <b>Portable Size:</b> Easy to carry, so you can manifest your
                  dreams wherever you go.
                </p>
                <p>
                  <b>Reflection Pages:</b> Regular prompts for self-reflection
                  and adjustments to your goals.
                </p>
                <p>
                  <b>Positive Affirmations:</b> Pages dedicated to writing and
                  reinforcing positive affirmations.
                </p>
              </p>
              <br></br>
              <br></br>
              <p>
                Only <b>$9.99</b>
              </p>
              <Button
                href={affiliateLinkManifestationJournal}
                target="_blank"
                rel="noopener noreferrer"
              >
                Buy the Softcover Edition
              </Button>
              <p>Available on Amazon</p>
              <br></br>
              <Button href={etsyLink} target="_blank" rel="noopener noreferrer">
                Get the Digital Version
              </Button>
              <p>Available on Etsy</p>
            </SubSection>
          </SectionContent>
        </Section>
        <Section backgroundColor="#d8d6d6">
          <SectionTitle>Suggested readings</SectionTitle>
          <SectionContent>
            <p>
              Discover these amazing books that can help you boost mindfulness,
              how to manifest your dreams, and live your best life.
            </p>
            <Link href={"https://amzn.to/3Vi3JvB"}>
              <SmallBookPreview src="super-attractor.png" alt="Book" />
              <p>
                Super Attractor: Methods for Manifesting a Life beyond Your
                Wildest Dreams
              </p>
            </Link>
            <Link href={"https://amzn.to/4512KmU"}>
              <SmallBookPreview src="mindfulness.png" alt="Book" />
              <p>
                Practicing Mindfulness: 75 Essential Meditations to Reduce
                Stress, Improve Mental Health, and Find Peace in the Everyday
              </p>
            </Link>
          </SectionContent>
        </Section>
        <Section ref={sectionRef3} style={fadeIn}>
          <Italic>More inspiration and tools coming soon, stay tuned!</Italic>
          <br />
          <br />
          <SectionContent>
            <Link
              href={"https://www.tiktok.com/@susandew_9"}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Social src="tiktok.png" className="App-social" alt="tiktok" />
            </Link>
            <p>© {new Date().getFullYear()} SusAndew</p>
          </SectionContent>
        </Section>
      </Container>
    </ParallaxProvider>
  );
};
export default App;
